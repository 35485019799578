import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const ChatsListSkeleton: React.FC = () => {
  return (
    <Grid item container direction="column" wrap="nowrap" rowGap={1}>
      <Skeleton animation="wave" width="100%" />

      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={40} />
    </Grid>
  );
};

export default ChatsListSkeleton;
