import { COLOURS } from '@core-ui/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { formatTime } from 'src/app/utils/dates';
import { ITempSendMessage } from 'src/pages/chats/types';

import Spinner from './components/Spinner';

interface IProps {
  post: ITempSendMessage;
}

const UserMessage: React.FC<IProps> = (props) => {
  const { post } = props;

  const userName = `${post.user?.first_name} ${post.user?.last_name}`;

  return (
    <Grid container direction="column">
      <Grid container columnGap={1} wrap="nowrap" alignItems="center">
        <Avatar variant="rounded" alt={`${post.id}`} />
        <Typography variant="h6" component="span">
          {userName}
        </Typography>
        <Typography
          variant="body2"
          component="span"
          sx={{
            marginLeft: '10px',
            fontFeatureSettings: "'ss02' on, 'liga' off",
          }}
        >
          {formatTime(Date.now(), { dateFormat: 'd MMM yyyy' })}
        </Typography>
        <Spinner />
      </Grid>
      <Typography variant="body1" marginLeft="48px" color={COLOURS.HUE_COCONUT.HUE_50}>
        {post.message}
      </Typography>
    </Grid>
  );
};

export default UserMessage;
