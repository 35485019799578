import { changePropState } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { GET, POST } from 'src/api/chatApi';
import { POST as POST_OCEAN } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { getAppUserSelector } from 'src/app/selectors';
import State from 'src/app/types/state';
import { EmployeeSchema } from 'src/generated';
import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { FileUploadInfo, IChannelPostsResponse, IChannelUser, ITempSendMessage } from '../types';
import * as actions from './actions';

function* getChatsPostsList({ payload }: Action<{ chatId: string }>) {
  try {
    const response: IChannelPostsResponse = yield call(GET, `/api/v4/channels/${payload.chatId}/posts`, {}, {});

    const arrayPosts = Object.values(response.posts).sort((a, b) => a.create_at - b.create_at);

    yield put(
      actions.setChatsPostsList({
        value: arrayPosts,
        hasData: !!arrayPosts.length,
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield all([
      call(responseError, e),
      put(actions.setChatsPostsList({ error: e as Error, hasData: false, isAllDataReceived: false })),
    ]);
  }
}

function* getChatsUsersList({ payload }: Action<{ chatId: string }>) {
  try {
    const response: IChannelUser[] = yield call(
      GET,
      '/api/v4/users',
      {
        in_channel: payload.chatId,
      },
      {}
    );

    yield put(
      actions.setChatsUsersList({
        value: response,
        hasData: !!response.length,
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield all([
      call(responseError, e),
      put(actions.setChatsUsersList({ error: e as Error, hasData: false, isAllDataReceived: false })),
    ]);
  }
}

function* sendChatMessage({ payload }: Action<actions.ISendChatMessage>) {
  const { files, message, chatId } = payload;
  const action = changePropState(actions.reducerNamePosts);
  const user: Nullable<EmployeeSchema> = yield select(getAppUserSelector);
  const postsToSend: ITempSendMessage[] = yield select((state: State) => state.chats.posts.postsToSend);
  yield put(
    action(
      ['postsToSend'],
      [
        ...postsToSend,
        {
          id: postsToSend.length,
          user,
          message,
          tempSendMessage: true,
        },
      ]
    )
  );

  let uploadedFileInfos: FileUploadInfo[] = [];

  try {
    if (files.length) {
      uploadedFileInfos = yield all(
        files.slice(0, 5).map((file) => {
          const formData = new FormData();
          formData.append('files', file);
          formData.append('channel_id', chatId);

          return call(POST, '/api/v4/files', formData, {});
        })
      );
    }

    yield call(
      POST,
      '/api/v4/posts',
      {
        channel_id: chatId,
        message,
        file_ids: uploadedFileInfos.map((info) => info.file_infos[0].id),
      },
      {}
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(
      action(
        ['postsToSend'],
        postsToSend.filter((item) => item.id !== postsToSend.length)
      )
    );
  }
}

function* notifyUser({ payload }: Action<actions.INotifyUser>) {
  const { mentions, chatId } = payload;

  try {
    yield all(
      mentions.map((mention) => {
        return call(POST_OCEAN, '/chat/mention', {
          email: mention.display,
          chat_id: chatId,
        });
      })
    );
  } catch (e) {
    yield null;
  }
}

function* addUser({ payload }: Action<actions.INotifyUser>) {
  const { mentions, chatId } = payload;

  try {
    yield all(
      mentions.map((mention) => {
        return call(POST_OCEAN, '/chats/add_user', {
          email: mention.display,
          chat_id: chatId,
        });
      })
    );
  } catch (e) {
    yield null;
  }
}

export default [
  takeLatest(actions.getChatsPostsList, getChatsPostsList),
  takeLatest(actions.getChatsUsersList, getChatsUsersList),
  takeEvery(actions.sendChatMessage, sendChatMessage),
  takeEvery(actions.addUser, addUser),
  takeEvery(actions.notifyUser, notifyUser),
];
