import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getChatListSelector = (state: State) => state.chats.list.value;
const getChatIdSelector = (_: State, props: { id: string }) => props.id;

const getCurrentChat = createSelector([getChatListSelector, getChatIdSelector], (chats, id) =>
  chats?.find((chat) => chat.id === id)
);

export default createSelector([getCurrentChat], (chat) => ({
  chat,
}));
