export const mentionStyles = {
  control: {},

  '&multiLine': {
    width: '100%',
    control: {},
    highlighter: {
      padding: 9,
    },
    input: {
      width: '100%',
      padding: 9,
    },
  },

  '&singleLine': {
    display: 'inline-block',

    highlighter: {
      padding: 1,
    },
    input: {
      padding: 1,
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      maxHeight: 100,
      overflow: 'auto',
      position: 'absolute',
      bottom: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
