import { getList, setList } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

import { IChannelPost, IChannelUser, IMention } from '../types';

export const reducerNamePosts = 'chats.posts';
export const reducerNameUsers = 'chats.users';

export const getChatsPostsList = getList(reducerNamePosts);
export const setChatsPostsList = setList<IChannelPost>(reducerNamePosts);

export const getChatsUsersList = getList(reducerNameUsers);
export const setChatsUsersList = setList<IChannelUser>(reducerNameUsers);

export interface ISendChatMessage {
  files: File[];
  chatId: string;
  message: string;
}

export const sendChatMessage = createAction<ISendChatMessage>(`${reducerNamePosts}.SEND_MESSAGE`);

export interface INotifyUser {
  mentions: IMention[];
  chatId: string;
}

export const notifyUser = createAction<INotifyUser>(`${reducerNamePosts}.NOTIFY_USER`);

export interface IAddUser {
  mentions: IMention[];
  chatId: string;
}

export const addUser = createAction<IAddUser>(`${reducerNamePosts}.ADD_USER`);
