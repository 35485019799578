import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getPostsSelector = (state: State) => state.chats.posts.value;
const getTempPostsSelector = (state: State) => state.chats.posts.postsToSend;

const getPostsWithTempPosts = createSelector([getPostsSelector, getTempPostsSelector], (posts, tempPosts) => [
  ...(posts ?? []),
  ...tempPosts,
]);

export default createSelector([getPostsWithTempPosts], (posts) => ({
  posts,
}));
