import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getUsersSelector = (state: State) => state.chats.users.value;

const getUserIdFromProps = (_: State, props: { id: string }) => props.id;

const getUserSelector = createSelector([getUsersSelector, getUserIdFromProps], (users, id) =>
  users?.find((u) => u.id === id)
);

export default () =>
  createSelector([getUserSelector], (user) => ({
    user,
  }));
