import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getChatsList = (state: State) => state.chats.list;

export default createSelector([getChatsList], (list) => ({
  items: list.value ?? [],
  loaded: list.loaded,
  loading: list.loading,
  hasData: list.hasData,
}));
