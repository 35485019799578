import Typography from '@mui/material/Typography';
import { ChatBoatIconOcean } from '@core-ui/icons';
import React from 'react';

const NoChannelSelected: React.FC = () => {
  return (
    <>
      <ChatBoatIconOcean />
      <Typography variant="h6">Select a chat room to start</Typography>
    </>
  );
};

export default NoChannelSelected;
