import IosShareIcon from '@mui/icons-material/IosShare';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'src/hooks/useAppSelector';
import React, { FC } from 'react';

import selector from './selector';

interface IProps {
  chatId: string;
}

const ChannelHeader: FC<IProps> = (props) => {
  const { chatId } = props;

  const { chat } = useAppSelector((state) => selector(state, { id: chatId }));

  return (
    <Grid item container alignItems="center" borderBottom="1px solid #EFF1F3">
      <Grid item xs container wrap="nowrap" component={Typography} variant="h6">
        <Typography>{chat?.display_name}</Typography>
      </Grid>
      <Grid item component={IconButton}>
        <IosShareIcon />
      </Grid>
    </Grid>
  );
};

export default ChannelHeader;
