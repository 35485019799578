// we use here GET from oceanApi because this endpoint is in the main server, not in the mattermost server
import { GET } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IChannel } from '../types';
import * as actions from './actions';

function* getChatsList() {
  // const boatId: number | null = yield select(vehicleIdSelector);

  try {
    const response: { channels: IChannel[] } = yield call(GET, '/chats/get_user_chats');

    yield put(
      actions.setChatsList({
        value: response.channels,
        hasData: Boolean(response.channels.length),
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield all([
      call(responseError, e),
      put(actions.setChatsList({ error: e as Error, hasData: false, isAllDataReceived: false })),
    ]);
  }
}

export default [takeLatest(actions.getChatsList, getChatsList)];
