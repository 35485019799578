import reducer, { List } from '@core-ui/reducers_handlers';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';

import { IChannelPost, IChannelUser, ITempSendMessage } from '../types';
import * as actions from './actions';

export type IChatsPostsListState = List<IChannelPost> & {
  postsToSend: ITempSendMessage[];
};
export type IChatsUsersListState = List<IChannelUser>;

export const postsState = Immutable<IChatsPostsListState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  postsToSend: [],
  isAllDataReceived: false,
});

export const usersState = Immutable<IChatsUsersListState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
});

export const postsHandleActions = handleActions<typeof postsState, unknown>(
  {},
  reducer<typeof postsState>(actions.reducerNamePosts, postsState)
);

export const usersHandleActions = handleActions<typeof usersState, unknown>(
  {},
  reducer<typeof usersState>(actions.reducerNameUsers, usersState)
);
