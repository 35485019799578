import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { IChannel } from 'src/pages/chats/types';
import React, { memo } from 'react';

interface IProps {
  item: IChannel;
  selectChat: (id: string) => void;
  cardColor: string;
}

const ChatCard: React.FC<IProps> = (props) => {
  const { item, selectChat, cardColor } = props;
  const clickHandler = () => selectChat(item.id);

  return (
    <Grid item sx={{ backgroundColor: cardColor, cursor: 'pointer' }}>
      <CardHeader
        title={item.display_name}
        subheader="Last message"
        titleTypographyProps={{ variant: 'h6' }}
        subheaderTypographyProps={{ variant: 'body2' }}
        sx={{ padding: '10px 12px' }}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        onClick={clickHandler}
      />
    </Grid>
  );
};

export default memo(ChatCard);
